
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string().required(
        t("user.userName") + t("common.isRequiredField")
      ),
      password: Yup.string()
        .min(4, t("user.password") + t("common.mustBeAtLeast4Characters"))
        .required(t("user.password") + t("common.isRequiredField")),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          // Go to page after successfully login
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("common.tryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    return {
      t,
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
